import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  position: relative;
  bottom: 0;
  width: 100%;
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const FooterLogo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 10px;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterProjectInfo = styled.div`
  text-align: center;
`;

export const FooterTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const FooterText = styled.p`
  font-size: 1.5rem;
  margin: 5px 0;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-size: 2rem;
  font-weight: 100;
`;
