import { Route, Routes } from 'react-router-dom';
// import Dashboard from './Components/Dashboard';
// import Registration from './Components/Registration';
// import Login from './Components/Login';
import Landing from './Components/LandingPage';
// import HomePage from './Components/HomePage';
import Team from './Components/TeamPage';

/**
 *
 */
function App() {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}

      {/* <Route path="/login" element={<Login />} /> */}

      {/* <Route path="/registration" element={<Registration />} /> */}

      <Route path="/" element={<Landing />} />

      {/* <Route path="/homepage" element={<HomePage />} /> */}

      <Route path="/team" element={<Team />} />

    </Routes>
  );
}

export default App;
