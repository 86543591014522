/* eslint-disable default-param-last, no-case-declarations */

import { SET_GLOBAL_LOADING } from './types';

/**
 * Default state for global loading
 * @type {{myBoxes: *[]}}
 */
export const initialState = {
  globalLoading: true
};

/**
 * My boxes reducer
 *
 * @param {object} state - Global state
 * @param {string} type - Action object with type
 * @param {boolean} payload - Action object payload
 *
 * @returns {Object} return updated state
 *
 * @author Ignat Kim <ignat.kim@toca.social>
 * @copyright (c) 2024 TOCA Social UK Limited registered UK 12620358. All rights reserved.
 */
const globalLoadingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: payload
      };
    default:
      return state;
  }
};

export default globalLoadingReducer;
