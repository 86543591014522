import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles, StyledTheme } from './Styles';
import FontStyles from './Styles/fonts';
import store from './Store/configureStore';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/*',
    element:
  <ThemeProvider theme={StyledTheme}>
    <FontStyles />

    <GlobalStyles />

    <App />
  </ThemeProvider>
  }
]);

root.render(
  <Provider store={store}>
    <RouterProvider router={router}>
      <ThemeProvider theme={StyledTheme}>
        <App />

        <FontStyles />

        <GlobalStyles />
      </ThemeProvider>
    </RouterProvider>
  </Provider>
);

reportWebVitals();
