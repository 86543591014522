import React, { useState } from 'react';
import {
  Header as StyledHeader,
  HeaderDiv,
  Burgermenu,
  BurgerIcon,
  Burgerspan,
  Menu,
  Navigation,
  Link as StyledLink,
  Logo,
  StyledA,
  NavigationList,
  NavigationListElement
} from './styles';
import logo from '../../Images/Omnicyto_logos-05.png';

/**
 *
 */
function Header() {
  const [openList, setOpenList] = useState(false);
  // const recipientEmail = 'contact@omnicyto.com';
  // const subject = '';
  // const body = '';

  /**
   * This function is used to handle the click event on the burger icon
   *
   * @category Function
   * @returns {void}
   */
  function handleClick() {
    setOpenList(!openList);
  }

  return (
    <StyledHeader>
      <HeaderDiv>
        <StyledLink to="/"><Logo src={logo} alt="logo" /></StyledLink>

        <NavigationList>
          <NavigationListElement>
            <StyledLink to="/team">
              Team
            </StyledLink>
          </NavigationListElement>

          <NavigationListElement>
            <StyledA
              // href={`https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
              href="mailto:contact@omnicyto.com"
            >
              Contact Us
            </StyledA>
          </NavigationListElement>
        </NavigationList>

        <Burgermenu>
          <BurgerIcon className={openList ? 'open' : ''} onClick={() => handleClick()}>
            <Burgerspan />

            <Burgerspan />

            <Burgerspan />
          </BurgerIcon>

          <Menu className={`menu ${openList ? 'open' : ''}`} open={openList}>
            <Navigation open={openList}>
              <NavigationListElement>
                <StyledLink to="/team">Team</StyledLink>
              </NavigationListElement>

              <NavigationListElement>
                <StyledA
                  // href={`https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
                  href="mailto:contact@omnicyto.com"
                >
                  Contact Us
                </StyledA>
              </NavigationListElement>
            </Navigation>
          </Menu>
        </Burgermenu>
      </HeaderDiv>
    </StyledHeader>
  );
}

export default Header;
