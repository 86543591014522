import React from 'react';
import {
  FooterContainer,
  FooterColumn,
  FooterLogo,
  FooterLinks,
  FooterProjectInfo,
  FooterTitle,
  FooterText,
  Link as StyledLink
} from './styles';
import logo from '../../Images/Omnicyto_logos-05.png';

/**
 *
 */
function Footer() {
  return (
    <FooterContainer>
      <FooterColumn>
        <FooterLogo src={logo} alt="logo" />

        <FooterText>
          Multi-omics made simple.
        </FooterText>
      </FooterColumn>

      <FooterColumn>
        <FooterTitle>About</FooterTitle>

        <FooterLinks>
          <StyledLink to="/">
            Platform
          </StyledLink>

          <StyledLink to="/team">
            Team
          </StyledLink>
        </FooterLinks>
      </FooterColumn>

      <FooterColumn>
        <FooterProjectInfo>
          <FooterTitle>Omnicyto</FooterTitle>

          <FooterText>Phone: +491623314356</FooterText>

          <FooterText>contact@omnicyto.com</FooterText>

          <FooterText>Location: Berlin, Germany</FooterText>
        </FooterProjectInfo>
      </FooterColumn>
    </FooterContainer>
  );
}

export default Footer;
