import React, { useState, useEffect, useRef } from 'react';
import {
  Wrapper,
  Container,
  Img,
  Videoblock,
  FirstVideo,
  SecondVideo,
  ThirdVideo,
  Text,
  Headline,
  Paragraph,
  Scability,
  ScabilityBlock,
  Background,
  FlexboxColumn,
  StickyText,
  Flexbox,
  Block,
  FullScreenDiv,
  BulletPoint,
  Div,
  Illustration,
  Animationp,
  Title,
  ChatContainer,
  MessageList,
  Video,
  P,
  TextBlock,
  ChatFlexBox
} from './styles';
import chat from '../../Images/icons/cytobot_log-14.png';
import constructor from '../../Images/video/Constructor.mp4';
import gating from '../../Images/video/gating 3.mp4';
import graph from '../../Images/video/Graph 2_white_background_fixed2.mp4';
import Error from '../../Images/illustrations/error1.png';
import Platform from '../../Images/illustrations/platform1.png';
import Roadmap from '../../Images/illustrations/roadmap1.png';
import ScabilityImg from '../../Images/illustrations/Scalability.jpg';
import FirstBlockImage from '../../Images/illustrations/Data_types.png';
import Header from '../Header';
import Footer from '../Footer';

const senders = {
  bot: 'bot',
  user: 'user'
};

const userQuestions = {
  howAppWorks: 'Give me a tutorial to using this application',
  howGatingWorks: 'How exactly does gating work again?'
};

const botReplies = {
  howItWorksReply: `OMNICYTO Gating Application Overview
- Selecting Samples:  Choose samples from the dropdown menu or by typing the sample name in the search bar.
- Switch between samples using the chevron buttons.
- Plot Settings: Adjust plot resolution, select x and y axes channels, and set axis limits.
- Clip gates to the axis to restrict dragging beyond plot borders.
- Changing Plot Size: Enter a new value in the Plot Resolution field for automatic update.
- Gating Modes: Switch between Rectangle, Polygon, Quadrant, and Interval gating modes.
- Channel Selection: Switch channels from the dropdown menu, with the selected channel highlighted.
- Swap axes using the Swap Axes button in the plot's upper right corner.
- Gating Hierarchy Tree: Expand and collapse the tree by clicking the arrows next to node names.
- Put a node in focus or activate a gate by clicking or double-clicking on it.
- Gate Management: Rename, copy, and delete gates by right-clicking on the node and selecting the desired option.`,

  howGatingWorksReply: `Gating is one of the most basic principles of cytometry analysis.
In it, your goal is to find the populations of interest using your panel of markers.
To do it, you simply select an area of the scatter plot, containing the cells you want to analyze, thus creating a filter.
By doing it in a step-wise manner you further refine the population of interest and create a tree of filters or "gates".
Before you start gating you should make sure you know the main parameters of the cell population you want to identify:
- What size are the cells?
- What markers do they express?
- Do their sizes change? (e.g. after activation)
A good idea is to start with excluding doublets - cells measured together by chance.
You can do this by plotting Forward Scatter Height (FSH) vs. Forward Scatter Area (FSA) and removing (not including in the gate) the cells on the fringes of the main population.
You can then start separating populations based on their Forward (FSC) and Side Scatter (SSC) signals.
Here, FSC intensity is proportional to the diameter of the cell and SSC correlates with the internal complexity of the cell (like granularity).
I see you have a panel, tailored for T cell profiling. If you need further tips on your gating strategy, just ask!
`
};

/**
 *
 */
function Landing() {
  const [messages, setMessages] = useState([
    {
      id: 1, sender: senders.bot, text: 'I see it`s your first time gating. Need help?'
    }
  ]);

  const [message, setMessage] = useState([
    {
      id: 1, sender: senders.bot, text: 'Ask me anything!'
    }
  ]);

  const [activeIndex, setActiveIndex] = useState(0);
  const blockRefs = useRef([]);
  const [isOnPlay, setIsOnPlay] = useState(false);
  const [isSecondOnPlay, setIsSecondOnPlay] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0, 'instant');
  }, []);

  useEffect(() => {
    if (blockRefs.current) {
      const currentRefs = blockRefs.current;

      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const index = currentRefs.indexOf(entry.target);
              setActiveIndex(index);
            }
          });
        },
        { threshold: 0.5 }
      );

      currentRefs.forEach(block => {
        if (block) {
          observer.observe(block);
        }
      });

      return () => {
        currentRefs.forEach(block => {
          if (block) {
            observer.unobserve(block);
          }
        });
      };
    }

    return '';
  }, []);

  const handlePlay = () => {
    if (window.scrollY > 4000) {
      setIsOnPlay(true);
    }
  };

  const handleSecondPlay = () => {
    if (window.scrollY > 5100) {
      setIsSecondOnPlay(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePlay);

    return () => {
      window.removeEventListener('scroll', handlePlay);
    };
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleSecondPlay);

    return () => {
      window.removeEventListener('scroll', handleSecondPlay);
    };
  }, []);

  useEffect(() => {
    if (isOnPlay) {
      const timer1 = setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          { id: 2, sender: senders.user, text: userQuestions.howAppWorks }
        ]);
      }, 3000);

      const timer2 = setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            id: 3, sender: senders.bot, text: botReplies.howItWorksReply
          }
        ]);
      }, 5000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }

    return undefined;
  }, [isOnPlay]);

  useEffect(() => {
    if (isSecondOnPlay) {
      const timer3 = setTimeout(() => {
        setMessage(prevMessages => [
          ...prevMessages,
          { id: 2, sender: senders.user, text: userQuestions.howGatingWorks }
        ]);
      }, 3000);

      const timer4 = setTimeout(() => {
        setMessage(prevMessages => [
          ...prevMessages,
          {
            id: 3, sender: senders.bot, text: botReplies.howGatingWorksReply
          }
        ]);
      }, 5000);

      return () => {
        clearTimeout(timer3);
        clearTimeout(timer4);
      };
    }

    return undefined;
  }, [isSecondOnPlay]);

  return (
    <Wrapper>
      <Container>

        <Header />

        <Headline>Multi-omics made simple.</Headline>

        <Video>
          <iframe
            style={{ borderRadius: '20px', width: '100%', height: '100%' }}
            src="https://www.youtube.com/embed/-1v-d1XPheM?si=1ZITrt16EBA9ghgD"
            title="YouTube video player"
            allow="autoplay"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Video>

        <Text>
          <Paragraph>
            <StickyText>
              <Text>
                <Title isActive={activeIndex === 0}>Truly multi-modal</Title>
              </Text>

              <Text>
                <Title isActive={activeIndex === 1}>Comprehensive solution</Title>
              </Text>

              <Text>
                <Title isActive={activeIndex === 2}>Scales with you</Title>
              </Text>
            </StickyText>
          </Paragraph>

          <Block>
            <FullScreenDiv ref={el => { blockRefs.current[0] = el; }}>
              <ScabilityBlock>
                <Scability src={FirstBlockImage} />
              </ScabilityBlock>
            </FullScreenDiv>

            <FullScreenDiv ref={el => { blockRefs.current[1] = el; }}>
              <BulletPoint>
                <Background>
                  <Div>
                    <Illustration src={Error} />

                    <p>Completely code-free</p>
                  </Div>

                  <Div>
                    <Illustration src={Platform} />

                    <P>All-in-1 platform</P>
                  </Div>

                  <Div>
                    <Illustration src={Roadmap} />

                    <p>End-to-end analysis</p>
                  </Div>
                </Background>
              </BulletPoint>
            </FullScreenDiv>

            <FullScreenDiv ref={el => { blockRefs.current[2] = el; }}>
              <ScabilityBlock>
                <Scability src={ScabilityImg} />
              </ScabilityBlock>
            </FullScreenDiv>
          </Block>
        </Text>

        <Paragraph>
          <TextBlock>
            <Animationp>
              <Title>Truly multi-modal</Title>
            </Animationp>

            <FullScreenDiv>
              <ScabilityBlock>
                <Scability src={FirstBlockImage} />
              </ScabilityBlock>
            </FullScreenDiv>
          </TextBlock>

          <TextBlock>
            <Animationp>
              <Title>Comprehensive solution</Title>
            </Animationp>

            <FullScreenDiv>
              <BulletPoint>
                <Background>
                  <Div>
                    <Illustration src={Error} />

                    <p>Completely code-free</p>
                  </Div>

                  <Div>
                    <Illustration src={Platform} />

                    <P>All-in-1 platform</P>
                  </Div>

                  <Div>
                    <Illustration src={Roadmap} />

                    <p>End-to-end analysis</p>
                  </Div>
                </Background>
              </BulletPoint>
            </FullScreenDiv>
          </TextBlock>

          <TextBlock>
            <Animationp>
              <Title>Scales with you</Title>
            </Animationp>

            <FullScreenDiv>
              <ScabilityBlock>
                <Scability src={ScabilityImg} />
              </ScabilityBlock>
            </FullScreenDiv>
          </TextBlock>
        </Paragraph>

        <Videoblock>
          <Flexbox>
            <FirstVideo
              id="element"
              autoPlay="autoplay"
              muted
              loop
            >
              <source src={constructor} type="video/mp4" />

              <track
                kind="captions"
                src="path-to-your-captions.vtt"
                srcLang="en"
                label="English"
                default
              />
            </FirstVideo>

            <FlexboxColumn>
              <Text>
                <Title>
                  Drag-and-Drop
                  Workflow Constructor
                </Title>
              </Text>

              <Animationp>
                Analyze your data interactively in real-time while keeping
                it reproducible with custom workflows.
              </Animationp>
            </FlexboxColumn>
          </Flexbox>

          <Flexbox>
            <FlexboxColumn>
              <Text>
                <Title>Familiar methods</Title>
              </Text>

              <Animationp>
                Use trusted methods with a fresh look
                or find a new way to interact with your data.
              </Animationp>
            </FlexboxColumn>

            <SecondVideo
              autoPlay="autoplay"
              muted
              loop
            >
              <source src={gating} type="video/mp4" />

              <track
                kind="captions"
                src="path-to-your-captions.vtt"
                srcLang="en"
                label="English"
                default
              />
            </SecondVideo>
          </Flexbox>

          <Flexbox>
            <ThirdVideo
              autoPlay="autoplay"
              muted
              loop
            >
              <source src={graph} type="video/mp4" />

              <track
                kind="captions"
                src="path-to-your-captions.vtt"
                srcLang="en"
                label="English"
                default
              />
            </ThirdVideo>

            <FlexboxColumn>
              <Text>
                <Title>ML in a couple of clicks</Title>
              </Text>

              <Animationp>
                Create and apply machine learning models or fall back on the trusty
                classical statistics.
              </Animationp>
            </FlexboxColumn>
          </Flexbox>
        </Videoblock>

        <FlexboxColumn>
          {isOnPlay && (
            <ChatFlexBox>
              <FlexboxColumn>
                <Img src={chat} />

                <Title>Integrated Copilot</Title>
              </FlexboxColumn>

              <Div>
                <ChatContainer>

                  <MessageList>
                    {messages.map(letter => (
                      <FlexboxColumn
                        key={letter.id}
                        style={{
                          alignItems: letter.sender === 'user' ? 'flex-end' : 'flex-start'
                        }}
                        $sender={senders.user}
                      >
                        <div
                          style={{
                            backgroundColor: letter.sender === 'user' ? '#87c0d2' : '#75a7b6',
                            padding: '2rem',
                            borderRadius: '20px',
                            color: 'white',
                            fontSize: '2.1rem'
                          }}
                        >
                          {letter.text}
                        </div>
                      </FlexboxColumn>
                    ))}
                  </MessageList>
                </ChatContainer>
              </Div>
            </ChatFlexBox>
          )}

          {isSecondOnPlay && (
          <ChatFlexBox>

            <Div>
              <ChatContainer>

                <MessageList>
                  {message.map(mail => (
                    <FlexboxColumn
                      key={mail.id}
                      style={{
                        alignItems: mail.sender === 'user' ? 'flex-end' : 'flex-start'
                      }}
                    >
                      <div style={{
                        backgroundColor: mail.sender === 'user' ? '#87c0d2' : '#75a7b6',
                        padding: '2rem',
                        borderRadius: '20px',
                        color: 'white',
                        fontSize: '2.1rem'
                      }}
                      >
                        {mail.text}
                      </div>
                    </FlexboxColumn>
                  ))}
                </MessageList>
              </ChatContainer>
            </Div>

            <FlexboxColumn>
              <Img src={chat} />

              <Title>
                With state-of-the-art method knowledge
              </Title>
            </FlexboxColumn>
          </ChatFlexBox>
          )}
        </FlexboxColumn>

        <Footer />
      </Container>
    </Wrapper>
  );
}

export default Landing;
