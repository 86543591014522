import { createGlobalStyle } from 'styled-components';
import media from './breakpoints';

export const StyledTheme = {
  colours: {
    primary: {
      white: '#ffffff',
      black: '#000000'
    },
    blue: {
      azureBlue: '#003fe2',
      deepSapphire: '#1e018a',
      deepLavender: '#3d2de1',
      electricBlue: '#5855e3',
      midnightIndigo: '#1a007b',
      deepPlum: '#100638',
      royalNavy: '#1f2bba',
      sereneBlue: '#8186ff',
      midnightBlue: '#17006d',
      twilightLavender: '#766cea',
      midnightSapphire: '#051274',
      royalAmethyst: '#3c1eb5',
      deepAmethyst: '#500cc1',
      sapphireBlue: '#1125d4',
      deepIndigo: '#120980',
      deepIndigoTransparent: 'rgba(18, 9, 128, 0.25)',
      buttonDarkBlue: '#2F3ABD',
      midnightNavy: '#0d0c7b',
      sapphireSplash: '#003edc',
      softLavender: '#dee0ff',
      transparentDarkBlue: '#100638BF',
      midnightRoyal: '#1b006d',
      duskViolet: '#716fe8',
      sereneLavender: '#cbceff',
      midnightAzure: '#2c2ab0',
      duskPurple: '#6762ae',
      deepPurple: '#390cd1',
      lavenderBlue: '#8b8ad5',
      airyLavender: '#ebecff',
      deepBlue: '#092D86',
      vibrantIndigo: '#3D2DE1',
      serenePeriwinkle: '#5f5cff',
      paleLavender: '#ECEBFF',
      deepBlueLavender: 'rgba(70, 88, 157, 0.6)',
      navyBlue: '#092d86',
      oceanicBlue: '#295de1',
      sapphireMarine: '#2148a9',
      mistySlate: '#7a87a7',
      softSky: '#d5e8ff',
      gentleBreeze: '#f2f5fe',
      ceruleanBlue: '#0662ec',
      slateBlue: '#5a6c99',
      paleBlue: '#99a3bb',
      periwinkle: '#9aa7dd',
      vidrantIndigo: '#461ECD',
      darkSapphire: '#061175',
      royalBlue: '#3D11D2',
      lavenderFog: '#d9dffe',
      gentleSky: '#eaeeff',
      sereneSlate: '#adb8d6',
      softLilac: '#dee2f6',
      cloudyBlue: '#bdc7de',
      tranquilBlue: '#94aae2',
      sereneSky: '#dfeaff',
      skyBlue: '#04c2fe',
      deepNavy: '#001547',
      navyNight: '#284795',
      deepCerulean: '#1f4fc7',
      softPeriwinkle: '#d0d8ff',
      lavenderIndigo: '#776cea',
      transparentPeriwinkle: '#8186FF80',
      softLavenderBlue: '#A7B2ED',
      lightSkyBlue: '#D5E6FF',
      softMorningBlue: '#D8F2FD',
      whisperingLavender: '#EDEDF5',
      vibrantSapphire: '#2C5EDD',
      lightBlue: 'rgba(61, 17, 210, 0.4)'
    },
    grayscale: {
      white: '#ffffff',
      shadowBlack: '#1c1b1f',
      black: '#000000',
      pureWhite: '#f4f4f4',
      ivorySnow: '#f7f6fe',
      lavenderGray: '#b1b4dd',
      midnightBlack: '#1e1f21',
      airyBlue: '#f2f3ff',
      charcoalGray: '#333235',
      transparentBlack: '#0000001a',
      graphiteGray: '#5c5b5d',
      gentleLilac: '#eae8fe',
      silverCloud: '#d6d6d7',
      darkGray: '#343537',
      lightGray: '#9faccc',
      whiteBlur: 'rgba(16, 6, 56, 0.75)',
      mistyBlue: '#959ab9',
      silverGray: '#e2e6ff',
      gentleMorningMist: '#D6DFEB'
    },
    green: {
      emeraldGreen: '#00cc8f',
      mintBreeze: '#59ffc6',
      freshMint: '#44e899'
    },
    yellow: {
      goldenSunrise: '#ffee51'
    },
    grey: {
      mediumGrey: '#808081',
      transparentGrey: 'rgba(0, 0, 0, 0.15)'
    },
    red: {
      coralRed: '#fd5f5f',
      rose: '#ff2d2d',
      brightRed: '#D3021B',
      transparentCrimsonRed: '#E00303B2',
      crimsonRed: '#d3021b'
    }
  },
  zIndex: {
    loader: 500,
    dialog: 401,
    blur: 400,
    header: 300,
    tooltip: 200,
    subHeader: 299,
    exerciseLibrary: 199,
    exercisesList: 198,
    exercisePanelFooter: 197,
    dropDown: 100,
    background: -1
  },
  fonts: {
    sfProText: {
      regular: 'SF-Pro-Text-Regular',
      medium: 'SF-Pro-Text-Medium',
      semiBold: 'SF-Pro-Text-SemiBold',
      bold: 'SF-Pro-Text-Bold'
    },
    sfPro: {
      regular: 'SF-Pro-Regular',
      medium: 'SF-Pro-Medium',
      bold: 'SF-Pro-Bold'
    },
    sfProDisplay: {
      regular: 'SF-Pro-Display-Regular'
    },
    sharpSans: {
      semiBold: 'SharpSans-SemiBold',
      bold: 'SharpSans-Bold'
    },
    titlingGothic: {
      skylineMedium: 'TitlingGothicFB-Skyline-Medium',
      extendedBold: 'TitlingGothicFB-Extended-Bold'
    }
  },
  sizes: {
    headerHeight: 4.8,
    subHeaderHeight: 5
  }
};

export const GlobalStyles = createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set REM base value */
  html {
    font-size: 62.5%; // 1rem = 10px
    background-color: #ffffff;

    @media ${media.xLarge} {
      font-size: 55%; // Example: 1rem = 8.8px for xLarge devices
    }

    @media ${media.large} {
      font-size: 50%; // Example: 1rem = 8px for large devices
    }

    @media ${media.medium} {
      font-size: 45%; // Example: 1rem = 7.2px for medium devices
    }

    @media ${media.small} {
      font-size: 40%; // Example: 1rem = 6.4px for small devices
    }
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font-family: 'SharpSans-Medium', sans-serif;
  }

  /* Add default fonts */
  h1,
  h4 {
    font-family: 'SharpSans-Medium', sans-serif;
    text-transform: uppercase;
  }

  h2 {
    font-family: 'SharpSans-Medium', sans-serif;
    text-transform: capitalize;
  }

  h3,
  h6 {
    font-family: 'SharpSans-Bold', sans-serif;
    text-transform: uppercase;
  }

  p,
  span {
    font-family: 'SharpSans-Medium', sans-serif;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;
