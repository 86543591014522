/* eslint-disable default-param-last */
import { CLEAR_GLOBAL_ERROR, SET_GLOBAL_ERROR } from './types';

export const initialState = {
  error: null
};

/**
 * globalError reducer
 *
 * @param {object} state - Global state
 * @param {string} type - Action object with type
 * @param {object} payload - Action object payload
 *
 * @returns {Object} return updated state
 *
 */
const globalErrorReducer = (
  state = initialState,
  {
    type,
    payload
  }
) => {
  switch (type) {
    case SET_GLOBAL_ERROR: {
      return {
        ...state,
        error: payload
      };
    }

    case CLEAR_GLOBAL_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    default:
      return state;
  }
};

export default globalErrorReducer;
