import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user/reducers';
import globalErrorReducer from './globalError/reducers';
import globalLoadingReducer from './globalLoading/reducers';

/**
 * Combine all reducers to one root reducer
 * The combineReducers helper function turns an object whose values are different
 * reducing functions into a single reducing function you can pass to createStore.
 *
 */
const rootReducer = combineReducers({
  globalError: globalErrorReducer,
  globalLoading: globalLoadingReducer,
  user: userReducer
});
export default rootReducer;
