import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

/**
 * Create store with middleware,
 * add composeEnhancers to use redux dev tools
 *
 * @see https://redux-toolkit.js.org/api/configureStore
 */
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
