/* eslint-disable default-param-last, no-case-declarations */
import { SET_USER_INFORMATION } from './types';

export const initialState = {
  user: null
};

/**
 * User reducer
 *
 * @param {object} state - Global state
 * @param {string} type - Action object with type
 * @param {boolean} payload - Action object payload
 *
 * @returns {Object} return updated state
 *
 */
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFORMATION:
      return {
        ...state,
        user: { ...state.user, ...payload }
      };
    default:
      return state;
  }
};

export default userReducer;
