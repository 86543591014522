import React, { useEffect } from 'react';
import {
  Wrapper,
  Conteiner,
  Text,
  Headline,
  PersonBlock,
  WorkImg,
  ImgBlock,
  WorkSmallImg,
  Paragraph,
  GridBlock,
  PersonImg,
  HeadlineSmall,
  ImgWorkBlock,
  StartedBlock,
  Title
} from './styles';
import Lev from '../../Images/people/Lev.png';
import Nika from '../../Images/people/Veronika.png';
import Vika from '../../Images/people/Viktoriia.png';
import Rostislav from '../../Images/people/Rostislav.png';
import Olufemi from '../../Images/people/Olufemi.png';
import Charite from '../../Images/work/Charite.png';
import BIH from '../../Images/work/BIH.png';
import Intersog from '../../Images/work/Intersog.png';
import CodeCare from '../../Images/work/CodeCare.png';
import Toca from '../../Images/work/Toca.png';
import Fu from '../../Images/work/FU.png';
import Tu from '../../Images/work/TU.png';
import OttoFriedrich from '../../Images/work/OttoFriedrich.png';
import Bucknell from '../../Images/work/Bucknell.png';
import Manchester from '../../Images/work/Manchester.png';
import Lucanet from '../../Images/work/Lucanet.png';
import Header from '../Header';
import Footer from '../Footer';

/**
 *
 */
function Team() {
  useEffect(() => {
    window.scrollTo(0, 0, 'instant');
  }, []);

  return (
    <Wrapper>
      <Conteiner>

        <Header />

        <Headline>We build tools for your next research project.</Headline>

        <HeadlineSmall>
          We want biomedical analysis to be available to everyone.
          <br />
          Easy, Pretty, Fast.
        </HeadlineSmall>

        <StartedBlock>
          <Text>
            <Title>How it started</Title>

            <Paragraph>
              I started working with multi-omic data in 2020 and quickly grew frustrated
              with the lack of tools for biomedical data analysis that would make
              it accessible for non-bioinformaticians.

              <br /><br />
              My experience showed that the analysis was the main
              bottleneck for many clinical scientists and wet-lab specialists.

              <br /><br />
              I started creating end-to-end pipelines with R to correct this,
              but quickly realized that a more holistic approach is desperately needed.

              <br /><br />
              We now build Omnicyto platform together with our talented
              team to bridge the gap between your data and results.
            </Paragraph>
          </Text>

          <PersonBlock>
            <PersonImg src={Lev} />

            <HeadlineSmall>
              Lev Petrov, M.Sc.
              <br />
              Immunologist and Bioinformatician
            </HeadlineSmall>

            <ImgBlock>
              <WorkSmallImg src={Charite} />

              <WorkSmallImg src={BIH} />
            </ImgBlock>
          </PersonBlock>
        </StartedBlock>

        <Text>
          <Title>How it is going</Title>

          <Paragraph>
            As soon as Rostislav with his 8 years of web development experience was on board,
            work on the project officially took off in August 2024.
            He makes sure our platform runs seamlessly and bug-free in the cloud.

            <br /><br />
            With the addition of Veronika - our Machine Learning expert,
            who is a post-doc and a team lead at TU Berlin with a broad network in the field,
            we could start designing our CytoBot - your copilot for any task on the platform.

            <br /><br />
            Viktoriia is another web developer,
            who joined us and is responsible for the intuitive interfaces of our application.

            <br /><br />
            Olufemi is an experienced bioinformatician with background in biophysics and
            mathematics, who is a post-doc at Charité Berlin and makes sure
            our algorithms work as intended.
          </Paragraph>
        </Text>

        <ImgBlock>
          <PersonBlock>
            <PersonImg src={Rostislav} />

            <HeadlineSmall>
              Rostyslav Shchehlov

              <br />

              Senior Web Dev.
            </HeadlineSmall>

            <ImgWorkBlock>
              <WorkImg src={Intersog} />

              <WorkImg src={CodeCare} />

              <WorkImg src={Toca} />
            </ImgWorkBlock>
          </PersonBlock>

          <PersonBlock>
            <PersonImg src={Nika} />

            <HeadlineSmall>
              Veronika Solopova
              <br />
              Dr. rer. nat.
              ML and Comp. Science
              Post-Doc Team Lead
            </HeadlineSmall>

            <GridBlock>
              <WorkImg src={Tu} />

              <WorkImg src={Fu} />

              <WorkImg src={OttoFriedrich} />

              <WorkImg src={Bucknell} />

              <WorkImg src={Manchester} />
            </GridBlock>
          </PersonBlock>

          <PersonBlock>
            <PersonImg src={Vika} />

            <HeadlineSmall>
              Viktoriia Herman

              <br />

              Junior+ Web Dev.
            </HeadlineSmall>

            <ImgWorkBlock>
              <WorkImg src={Lucanet} />
            </ImgWorkBlock>
          </PersonBlock>

          <PersonBlock>
            <PersonImg src={Olufemi} />

            <HeadlineSmall>
              Olufemi Bolaji Ademola

              <br />

              PhD
              Bioinformatics Post-Doc
            </HeadlineSmall>

            <ImgWorkBlock>
              <WorkImg src={Charite} />

              <WorkImg src={BIH} />
            </ImgWorkBlock>
          </PersonBlock>

        </ImgBlock>

        <Footer />
      </Conteiner>
    </Wrapper>
  );
}

export default Team;
