import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 25rem;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 16px;
    justify-content: space-between;
    font-size: 16px;
    background-color: ${props => props.theme.colours.primary.white};
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -0.9rem; 
`;

export const Container = styled.div`
    width: 100%;
    max-width: 134rem;
    margin: 0 auto;
    padding: 0 1rem; 
`;

export const Ul = styled.ul`
    list-style-type: none;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 320px) {
        display: none;
    }
        
    @media (min-width: 768px) {
        display: flex;
        margin-top: 2rem;
    }
`;

export const Img = styled.img`
    width: 14%;
    display: block;
`;

export const Paragraph = styled.div`
    position: relative;
    width: 50%;
    margin-top: 50vh;
    @media (min-width: 320px) and (max-width: 426px) {
      width: 100%;
      margin-top: 20vh;
    }
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  background: linear-gradient(90deg, #FFA800 0%, #B880FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative; 

  &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px; 
      width: 100%;
      height: 2px; 
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
  }

  @media (min-width: 425px) {
    &::after {
      background-color: ${props => (props.isActive ? '#FFA800' : 'transparent')};
    }
  }
`;
export const Li = styled.li`
    list-style-type: none;
    padding: 1.2rem 0.5rem;
`;

export const FirstVideo = styled.video`
  width: 50%;
  border-radius: 20px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

export const SecondVideo = styled.video`
  width: 50%;
  border-radius: 20px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

export const ThirdVideo = styled.video`
  width: 50%;
  border-radius: 20px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

export const Videoblock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.3rem;   
    margin: 9rem 0rem;
    justify-content: space-between; 
    border-radius: 20px;
    overflow: hidden;

    @media (min-width: 320px) {
        display: block;
        width: 100%;
    }
    @media (min-width: 768px) {
        display: flex;
    }
`;

export const Headline = styled.h1`
    display: block;
    margin: 18rem 0rem 13rem 0rem;
    text-align: center;
    text-transform: none;
    font-weight: bold;
    font-size: 160px;

    @media (min-width: 320px) and (max-width: 426px) {
        font-size: 86px;
    }
`;

export const Text = styled.div`
  display: flex;
  margin-bottom: 4rem;
  flex-direction: row;
`;

export const StickyText = styled(Text)`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);


    @media (min-width: 320px) and (max-width: 426px) {
      top: 0%;
      display: none;
      transform: translateY(-0%);
    }
`;

export const Block = styled.div`
    display: flex;
    gap: 5rem;
    margin-top: 4rem;
    flex-direction: column;
    width: 35%;
    align-items: center;

    @media (min-width: 320px) and (max-width: 426px) {
      width: 100%;
      display: none;
      flex-direction: column;
      gap: 0rem;
    }
`;

export const BulletPoint = styled.div`
  background-color: black;
  border-radius: 2rem;
  gap: 2rem;
  font-size: 2rem;
   width: 100%;
  color: #ffc81d;
  display: flex;
  padding: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in, transform 1s ease-in;
`;

export const ScabilityBlock = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in, transform 1s ease-in;
`;

export const Div = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
`;

export const FullScreenDiv = styled(Div)`
    height: calc(80vh - 97px);
    width: 100%;
    position: relative;
`;

export const FlexboxColumn = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
    flex: 50%;
    flex-direction: column;
`;

export const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  margin-bottom: 15rem;

  @media (min-width: 320px) and (max-width: 769px) {
    flex-wrap: wrap;

    &:nth-child(1) {
      flex-wrap: wrap-reverse;    
    }

    &:nth-child(3) {
      flex-wrap: wrap-reverse;    
    }
  }
`;
export const ChatFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  margin-bottom: 15rem;

  @media (min-width: 320px) and (max-width: 769px) {
    flex-wrap: wrap;

    &:nth-child(2) {
      flex-wrap: wrap-reverse;    
    }
  }
`;

export const Background = styled.div`
    background-color:white;
    padding: 37px;
    color: black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
`;

export const Illustration = styled.img`
  width: 14%;
`;

export const Scability = styled.img`
  width: 80%;
`;

export const P = styled.p`
  padding-left: 3px;
`;

export const Animationp = styled.p`
   animation: blink-caret 0.75s step-end infinite;
   flex: 50%;
   font-size: 20px;

    @keyframes blink-caret {
      from { border-color: transparent; }
      to { border-color: black; }
    }
`;
export const ChatContainer = styled.div`
  border-radius: 20px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  white-space: break-spaces;
  border-radius: 5px;
`;

export const Video = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16rem;
    width: 96vw;
    height: 100vh;
    margin: 0;

  @media (min-width: 768px) {
    position: relative;
    width: 62vw;
    height: 80vh;
    margin: 0 auto;
  }
`;
export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 320px) and (max-width: 426px) {
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 426px){
    display: none;
  }
`;
