export const screenSizes = {
  xxLarge: 1600,
  xLarge: 1200,
  large: 1020,
  medium: 880,
  small: 576,
  xSmall: 480
};

const media = {
  xxLarge: `(max-width: ${screenSizes.xxLarge}px)`,
  xLarge: `(max-width: ${screenSizes.xLarge}px)`,
  large: `(max-width: ${screenSizes.large}px)`,
  medium: `(max-width: ${screenSizes.medium}px)`,
  small: `(max-width: ${screenSizes.small}px)`,
  xSmall: `(max-width: ${screenSizes.xSmall}px)`
};

export default media;
