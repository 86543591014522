import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 25rem;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 16px;
    justify-content: space-between;
    font-size: 16px;
    background-color: #FFFFFF;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -0.9rem; 
`;

export const Conteiner = styled.div`
    width: 100%;
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 1rem; 
`;

export const Ul = styled.ul`
    list-style-type: none;
    display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 320px) {
        display: none;
    }
    @media (min-width: 768px) {
        display: flex;
        margin-top: 2rem;
    }
`;

export const Div = styled.div`
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 10;

    width: 100%;
    max-width: 1340px;
    margin: 0 auto; 
    padding: 0 0.9rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
`;

export const HeaderDiv = styled.div`
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 10;

    width: 100%;
    max-width: 1340px;
    margin: 0 auto; 

    display: flex;
    justify-content: space-between;
    align-items: center;
}
`;

export const Header = styled.header`
    list-style-type: none;
    margin-bottom: 10rem;

    @media (min-width: 768px) and (max-width: 960px) {
        margin-bottom: 25rem;
    }
`;

export const Img = styled.img`
    width: 14%;
    display: block;
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
`;

export const Paragraph = styled.div`
    font-size: 21px;
    margin: 4rem 0rem 3rem 0rem;
`;

export const StartedBlock = styled.div`
    display: flex;
    margin-top: 5rem;
    gap: 2rem;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        margin-top: 5rem;
        gap: 2rem;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        align-items: center;
    }
`;

export const Title = styled.h2`
    font-weight: bold;
    font-size: 30px;
`;

export const Li = styled.li`
    list-style-type: none;
    padding: 1.2rem 0.5rem;
`;

export const Headline = styled.h1`
    display: block;
    margin: 12rem 0rem 3rem 0rem;
    text-align: center;
    text-transform: none;
    font-weight: bold;
    font-size: 35px;
`;

export const HeadlineSmall = styled.h1`
    display: block;
    line-height: 3rem;
    text-align: center;
    text-transform: none;
    font-size: 21px;
`;

export const Burgermenu = styled.div`
  display: none;
  position: relative;

  @media (min-width: 320px) {
    display: block;
    margin: 4rem;
  }
   @media (min-width: 768px) {
        display: none;
    }
`;

export const BurgerIcon = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -7px);
  }
`;

export const Burgerspan = styled.span`
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
`;

export const Menu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  flex-direction: column;
  padding: 10px;

  &.open {
    display: flex;
  }
`;

export const PersonBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 25%;
    align-items: center;

    @media (max-width: 768px) {
        width: 75%;
    }
`;

export const ImgWorkBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
`;

export const PersonImg = styled.img`
    height: 40%;
`;

export const WorkSmallImg = styled.img`
    width: 40%;
    margin: 1rem;
    height: 30%;
`;

export const ImgBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
`;

export const WorkImg = styled.img`
    // height: 79%;
    // width: 83%;
    margin: 1rem;

`;

export const GridBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 49% 49%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
