import { createGlobalStyle } from 'styled-components';

import SFProTextRegular from './SF-Pro-Text-Regular.ttf';
import SFProTextMedium from './SF-Pro-Text-Medium.ttf';
import SFProTextSemiBold from './SF-Pro-Text-SemiBold.ttf';
import SFProTextBold from './SF-Pro-Text-Bold.ttf';
import SFProRegular from './SF-Pro-Regular.OTF';
import SFProDisplayRegular from './SF-Pro-Display-Regular.OTF';
import SFProBold from './SF-Pro-Bold.OTF';
import SFProMedium from './SF-Pro-Medium.OTF';
import SharpSansSemiBold from './SharpSans-SemiBold.ttf';
import SharpSansBold from './SharpSans-Bold.ttf';
import TitlingGothicSkylineMedium from './TitlingGothicFB-Skyline-Medium.ttf';
import TitlingGothicExtendedBold from './TitlingGothicFB-Extended-Bold.ttf';

const FontStyles = createGlobalStyle`
  /**
    * SF Pro Text
   */
  @font-face {
    font-family: 'SF-Pro-Text-Regular';
    src: url(${SFProTextRegular});
  }
  @font-face {
    font-family: 'SF-Pro-Text-Medium';
    src: url(${SFProTextMedium});
  }
  @font-face {
    font-family: 'SF-Pro-Text-SemiBold';
    src: url(${SFProTextSemiBold});
  }
  @font-face {
    font-family: 'SF-Pro-Text-Bold';
    src: url(${SFProTextBold});
  }
  
  /**
  * SF Pro
  */
  @font-face {
    font-family: 'SF-Pro-Regular';
    src: url(${SFProRegular});
  }
  @font-face {
    font-family: 'SF-Pro-Medium';
    src: url(${SFProMedium});
  
  }
  @font-face {
    font-family: 'SF-Pro-Bold';
    src: url(${SFProBold});
  }

  /**
  * SF Pro
  */
  @font-face {
    font-family: 'SF-Pro-Display-Regular';
    src: url(${SFProDisplayRegular});
  }

  /**
   * Sharp Sans
  */
  @font-face {
    font-family: 'SharpSans-SemiBold';
    src: url(${SharpSansSemiBold});
  }
  @font-face {
    font-family: 'SharpSans-Bold';
    src: url(${SharpSansBold});
  }

  /**
   * Titling Gothic FB
   */
  @font-face {
    font-family: 'TitlingGothicFB-Skyline-Medium';
    src: url(${TitlingGothicSkylineMedium});
  }
  @font-face {
    font-family: 'TitlingGothicFB-Extended-Bold';
    src: url(${TitlingGothicExtendedBold});
  }

`;

export default FontStyles;
