import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationList = styled.ul`
    list-style-type: none;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 320px) {
        display: none;
    }
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 58px 106px;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
    }
`;
export const Navigation = styled.ul`
    list-style-type: none;
    display: none;
    gap: 2rem;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 320px) {
        display: flex;
    }
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 58px 106px;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
    }
`;

export const HeaderDiv = styled.div`
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 10;

    width: 100%;
    max-width: 1340px;
    margin: 0 auto; 

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Header = styled.header`
    list-style-type: none;
    margin-bottom: 10rem;

    @media (min-width: 768px) and (max-width: 960px) {
        margin-bottom: 25rem;
    }
`;

export const Logo = styled.img`
    width: 14%;
    @media (min-width: 320px) and (max-width: 426px) {
        width: 54%;
    }
    @media (min-width: 425px) and (max-width: 1024px) {
        width: 24%;
    }
`;

export const NavigationListElement = styled.li`
    list-style-type: none;
    padding: 1.2rem 0.5rem;
    font-weight: bold;
    color: black;
    font-size: 2rem;
`;

export const Burgermenu = styled.div`
  display: none;
  position: relative;

  @media (min-width: 320px) {
    display: block;
    margin: 4rem;
  }
    
   @media (min-width: 768px) {
      display: none;
    }
`;

export const BurgerIcon = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -7px);
  }
`;

export const Burgerspan = styled.span`
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
`;

export const Menu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  flex-direction: column;
  padding: 10px;

  &.open {
    display: flex;
    flex-direction: column; 
    width: 18rem;
  }
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-size: 2rem;
  font-weight: 100;
`;

export const StyledA = styled.a`
  text-decoration: none;
  color: black;
  font-size: 2rem;
  font-weight: 100;
`;
